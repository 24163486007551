import {
  Collapse,
  Box,
  Flex,
  IconButton,
  Button,
  HStack,
  VStack,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import './animation.css';
import { Link } from 'react-router-dom';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import logo from './assets/logo2.png';

function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Control the mobile menu toggle
  const toggleMenu = () => (isOpen ? onClose() : onOpen());
 

  return (
    <Box
      backgroundImage="linear-gradient(to right,gray.100, blue.300,cyan.300)"
      w="100%"
      px={4}
      py={2}
      position="sticky"
      top="0"
      zIndex="10"
    >
      <Flex justify="space-between" align="center" w="100%">
        {/* Logo */}
        <Link to="/">
          <Image src={logo} alt="Logo" h={{ base: '12', md: '20' }} rounded="full" />
        </Link>

        {/* Request Call Button (Visible on mobile) */}
       <Button
          as={Link}
          to="/register"
          display={{ base: 'flex', md: 'none' }}
          bg="blue.400"
          color="white"
          fontSize="md"
          rounded="lg"
          fontWeight="bold"
        >
          Request Call
        </Button>

        {/* Desktop Menu */}
        <HStack
          as="nav"
          gap="2em"
          display={{ base: 'none', md: 'flex' }}
          align="center"
        >
          <Button border="2px solid black"  transition="all 0.3s ease-in-out" _hover={{transform:"scale(1.05)", bg:"white"}} borderRadius="0.8rem" bg="transparent" as={Link} to="/">Home</Button>
          <Button border="2px solid black"  transition="all 0.3s ease-in-out" _hover={{transform:"scale(1.05)", bg:"white"}} borderRadius="0.8rem" bg="transparent" as={Link} to="/services">Services</Button>
          <Button border="2px solid black"  transition="all 0.3s ease-in-out" _hover={{transform:"scale(1.05)", bg:"white"}} borderRadius="0.8rem" bg="transparent" as={Link} to="/about">About Us</Button>
          <Button border="2px solid black"  transition="all 0.3s ease-in-out" _hover={{transform:"scale(1.05)", bg:"white"}} borderRadius="0.8rem" bg="transparent" as={Link} to="/contact">Contact</Button>
        </HStack>


         {/* Request Call Button (Visible on desktop) */}
         <Button
          as={Link}
          to="/register"
          bg="blue.400"
          color="white"
          fontSize="xl"
          fontWeight="semibold"
          rounded="lg"
          border="2px solid white"
          p="1em"
          display={{ base: 'none', md: 'flex' }}
          transition="all 0.3s ease-in-out"
          _hover={{bg:"blue.200", transform:"scale(1.05)"}}
        >
          Request Call
        </Button>

          {/* Hamburger Menu Icon for small screens */}
          <IconButton
          size="md"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Toggle Navigation"
          display={{ md: 'none' }}
          onClick={toggleMenu}
        />

      </Flex>

      {/* Mobile Menu */}
      {isOpen && (
        <Collapse in={isOpen} animateOpacity>
        <VStack
         animation={isOpen ? "slide-in 1s " : "slide-out 3s "}
          spacing={4}
          align="center"
          mt={2}
          display={{ base:"flex", md: 'none' }}
          bg="cyan.300"
          p={4}
          rounded="lg"
          position="absolute"
          w="100%"
          zIndex="10"
          right="0"
        >
          <Button borderRadius="xl" bg="white" _hover={{bg:"blue.400"}} width="full" as={Link} to="/" animation={isOpen ? "slide-in 1s " : "slide-out 3s "} >Home</Button>
          <Button borderRadius="xl" bg="white" _hover={{bg:"blue.400"}} width="full" as={Link} to="/services" animation={isOpen ? "slide-in 1.5s " : "slide-out 3s "} >Services</Button>
          <Button borderRadius="xl" bg="white" _hover={{bg:"blue.400"}} width="full" as={Link} to="/about" animation={isOpen ? "slide-in 2s " : "slide-out 3s "} >About Us</Button>
          <Button borderRadius="xl" bg="white" _hover={{bg:"blue.400"}} width="full" as={Link} to="/contact" animation={isOpen ? "slide-in 2.5s " : "slide-out 3s "} >Contact</Button>
        </VStack>
        </Collapse>
      )}
    </Box>
  );
}

export default Navbar;
