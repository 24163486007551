
import './animation.css'; // Import your custom CSS file
import htmlImage from "./assets/html.png";
import reactImage from "./assets/react.png";
import cssImage from "./assets/css.png";
import jsImage from "./assets/js3.png"
import asImage from "./assets/as2.png";
import psImage from "./assets/ps.png";
import corelImage from "./assets/corel.png";

import image4 from "./assets/review4.jpg";
import image5 from "./assets/review5.jpg";
import image6 from "./assets/review6.jpg";
import image7 from "./assets/review7.jpg";
import image8 from "./assets/review8.jpg";
import image9 from "./assets/review9.jpg";
import image10 from "./assets/review10.jpg";

import webDesign from "./assets/webdesign.png";
import graphicsDesign from "./assets/graphicsdesign.png";
import digitalMarketing from "./assets/digitalmarketing.png";
import logoDesign from "./assets/logodesign2.png";

import "./animation.css";

function Home() {
  return (
    <div>
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-500 to-indigo-400 py-8">

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="text-4xl font-extrabold text-white mb-6">
          Empowering Your Business Through Innovative Design and Development.
          </h1>
          <p className="text-xl text-gray-300 font-bold mb-8">
            Your Menifestation, Our Creation
          </p>
        <div className="flex justify-center w-full max-md:flex">
        <img
                src={htmlImage}
                className="w-16 h-16 max-md:w-12 max-md:h-12 rounded-full shadow-lg animate-horizontal"
                alt="HTML"
              />
              <img
                src={cssImage}
                className="w-16 h-16 max-md:w-12 max-md:h-12 rounded-full shadow-lg animate-horizontal"
                alt="CSS"
              />
               <img
                src={jsImage}
                className="w-16 h-16 max-md:w-12 max-md:h-12 rounded-full shadow-lg animate-horizontal"
                alt="js"
              />
                <img
                src={reactImage}
                className="w-16 h-16 max-md:w-12 max-md:h-12 rounded-full shadow-lg animate-react"
                alt="React"
              />
              <img
                src={asImage}
                className="w-16 h-16 max-md:w-12 max-md:h-12 rounded-full shadow-lg animate-horizontal"
                alt="js"
              /><img
              src={corelImage}
              className="w-16 h-16 max-md:w-12 max-md:h-12 rounded-full shadow-lg animate-horizontal"
              alt="js"
            /><img
            src={psImage}
            className="w-16 h-16 max-md:w-12 max-md:h-12 rounded-full shadow-lg animate-horizontal"
            alt="js"
          />
        </div>
        </div>
      </div>

      {/* Categories Section */}
<div className="py-16 bg-gray-100">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
    <h2 className="text-3xl font-extrabold text-gray-900 mb-6">Popular Services</h2>
    <div className="flex flex-wrap justify-center gap-5 w-full">
      
      <div
        className="bg-white bg-center bg-cover shadow-md rounded-lg p-4 w-60 h-60 relative hover:bg-blue-500 hover:text-white hover:shadow-lg transition-shadow transform hover:scale-105 service-card hover:rotate-2 hover:-translate-y-2"
        style={{ backgroundImage: `url(${webDesign})` }}>
        <p className="absolute top-0 left-0 rounded-lg right-0 font-extrabold text-xl bottom-0 bg-blue-500 text-green-400 opacity-0 hover:opacity-100 flex items-center justify-center p-4 transition-all hover:scale-105 transform ">
          Rs. 4999/ Onwards
        </p>
        <h3 className="text-lg absolute left-0 right-0 bottom-0 font-bold">Web Designing</h3>
      </div>

      <div
        className="bg-white service-card bg-center bg-cover shadow-md rounded-lg p-4 w-60 h-60 relative hover:bg-blue-500 hover:text-white hover:shadow-lg transition-shadow transform hover:scale-105 hover:rotate-2 hover:-translate-y-2"
        style={{ backgroundImage: `url(${graphicsDesign})` }}>
        <p className="absolute top-0 left-0 rounded-lg right-0 bottom-0 font-extrabold text-xl bg-blue-500 text-green-400 opacity-0 hover:opacity-100 flex items-center justify-center p-4 transition-all transform hover:scale-105">
          Rs. 999/ Onwards
        </p>
        <h3 className="text-lg absolute bottom-0 left-0 right-0 font-bold">Graphics Designing</h3>
      </div>

      <div
        className="bg-white service-card bg-center bg-cover text-center shadow-md rounded-lg p-4 w-60 h-60 relative hover:bg-blue-500 hover:text-white hover:shadow-lg transition-shadow transform hover:scale-105 hover:rotate-2 hover:-translate-y-2"
        style={{ backgroundImage: `url(${logoDesign})` }}>
        <p className="absolute top-0 left-0 rounded-lg right-0 bottom-0 font-extrabold text-xl bg-blue-500 text-green-400 opacity-0 hover:opacity-100 flex items-center justify-center p-4 transition-all transform hover:scale-105">
          Rs. 299/ Onwards
        </p>
        <h3 className="text-lg absolute left-0 right-0 bottom-0 font-bold">Logo Designing</h3>
      </div>

      <div
        className="bg-white service-card bg-center bg-cover shadow-md rounded-lg p-4 w-60 h-60 relative hover:bg-blue-500 hover:text-white hover:shadow-lg transition-shadow transform hover:scale-105 hover:rotate-2 hover:-translate-y-2"
        style={{ backgroundImage: `url(${digitalMarketing})` }}>
        <p className="absolute top-0 left-0 rounded-lg right-0 bottom-0 font-extrabold text-xl bg-blue-500 text-green-400 opacity-0 hover:opacity-100 flex items-center justify-center p-4 transition-all transform hover:scale-105">
          Rs. 2999/ Onwards
        </p>
        <h3 className="text-lg absolute bottom-0 left-0 right-0 font-bold">Digital Marketing</h3>
      </div>

    </div>
  </div>
</div>

      {/* Testimonials Section */}
      <div className="py-16 bg-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 mb-6">What are Our Objectives</h2>
          <div className="flex flex-wrap -mx-4">
           
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="bg-white shadow-md rounded-lg p-6 hover:scale-105 duration-500">
                  <p className="text-gray-600 mb-4">
                    We aim to help our clients to grow leveraging them quality of services.
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="bg-white shadow-md rounded-lg p-6 hover:scale-105 duration-500">
                  <p className="text-gray-600 mb-4">
                    We understand client's requirement and try our best to fulfill their expectations. 
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="bg-white shadow-md rounded-lg p-6 hover:scale-105 duration-500">
                  <p className="text-gray-600 mb-4">
                    We ensure client satisfaction and trust in every aspect of our work.
                  </p>
                </div>
              </div>
          </div>
        </div>
      </div>
 
      <div className="relative overflow-hidden bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-extrabold text-gray-900 mb-6">Our Satisfied Users Feedbacks</h2>
        <div className="flex overflow-hidden">
          {/* Image wrapper with infinite scroll */}
          <div className="flex animate-scroll p-4 gap-2">
            {/* Loop through images to create a carousel */}
            {[image4, image5, image6, image7, image8, image9, image10].map((image, index) => (
              <div key={index} className="w-50 h-40 flex-shrink-0 bg-white shadow-md rounded-lg overflow-hidden">
                <img
                  src={image}
                  alt={`Review ${index + 1}`}
                  className="w-full h-full object-cover transition-all duration-400 ease-in-out hover:scale-105"
                /> 
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Home;
