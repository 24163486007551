// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyArOmJxaxZc-Qkg4H4NUNBHhtBNapHye1Y",
  authDomain: "reactproject-66ca9.firebaseapp.com",
  databaseURL: "https://reactproject-66ca9-default-rtdb.firebaseio.com",
  projectId: "reactproject-66ca9",
  storageBucket: "reactproject-66ca9.appspot.com",
  messagingSenderId: "771406851299",
  appId: "1:771406851299:web:80d5b57304a9f027e4e9d6",
  measurementId: "G-YL00BFC0TQ"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);

export { auth,database, ref, set, createUserWithEmailAndPassword, signInWithEmailAndPassword };
